<!--消费模块--设备-->
<template>
  <div
    id="equipment"
    v-loading="loading"
    :element-loading-text="$t('consume.a1')"
  >
    <div class="main" ref="main">
      <div class="card_container">
        <div
          class="card"
          v-for="item in cardlist"
          :key="item.value"
          @click="details(item.consumeReaderId)"
        >
          <img :src="getImg(item.status)" />
          <div class="title" :title="item.consumeReaderName">
            <span>{{ item.consumeReaderName }}</span>
          </div>
        </div>
        <div class="empty" v-for="n in 10" :key="'m' + n"></div>
      </div>
    </div>
    <div class="footer">
      <ul class="express">
        <li class="expressli" v-for="(item, index) in icon" :key="index">
          <img :src="item.url" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <!-- 设备详情对话框 -->
    <a-modal
      :title="$t('consume.a2')"
      centered
      :visible="visible"
      :width="800"
      :getContainer="() => this.$refs.main"
      @cancel="Cancel"
      :destroyOnClose="true"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel"> {{ $t("consume.a3") }} </a-button>
      </template>
      <div v-loading="detailsLoading" :element-loading-text="$t('consume.a1')">
        <div class="info">
          <my-tabletitle> {{ $t("consume.a4") }}</my-tabletitle>
          <a-table
            :columns="infoColumns"
            :data-source="infoData"
            :pagination="false"
            bordered
            :rowKey="(record) => record.status"
          />
        </div>
        <div class="time">
          <my-tabletitle>{{ $t("consume.a5") }}</my-tabletitle>
          <a-table
            :columns="timeColumns"
            :data-source="timeData"
            :pagination="false"
            bordered
            :rowKey="(record) => record.snack"
          />
        </div>
        <div>
          <my-tabletitle>{{ $t("consume.a6") }}</my-tabletitle>
          <a-table
            :columns="priceColumns"
            :data-source="priceData"
            :pagination="false"
            bordered
            :rowKey="(record) => record.mon"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import { getEquipmentList, getDetailsInfo } from "../../api/consume";

export default {
  name: "equipment",
  data() {
    return {
      loading: false,
      visible: false,
      detailsLoading: false,
      cardlist: [],
      icon: [
        {
          name: this.$t("consume.a7"),
          url: require("../../../public/images/door/equipment01.png"),
        },
        {
          name: this.$t("consume.a8"),
          url: require("../../../public/images/door/equipment03.png"),
        },
      ],
      priceData: [
        {
          mon: "-",
          tue: "-",
          wed: "-",
          thurs: "-",
          friday: "-",
          saturday: "-",
          sunday: "-",
          lunch: "-",
          supper: "-",
          snack: "-",
        },
      ],
      timeData: [
        {
          breakfast: "08:00~09:00",
          lunch: "12:00~13:00",
          supper: "18:00~19:00",
          snack: "22:00~23:00",
        },
      ],
      infoData: [
        {
          consumeReaderName: "-",
          sitename: "-",
          clientName: "-",
          status: "-",
        },
      ],
      timeColumns: [
        {
          title: this.$t("consume.a9"),
          dataIndex: "breakfast",
          align: "center",
        },
        {
          title: this.$t("consume.a10"),
          dataIndex: "lunch",
          align: "center",
        },
        {
          title: this.$t("consume.a11"),
          dataIndex: "supper",
          align: "center",
        },
        {
          title: this.$t("consume.a12"),
          dataIndex: "snack",
          align: "center",
        },
      ],
      infoColumns: [
        {
          title: this.$t("consume.a13"),
          dataIndex: "consumeReaderName",
          align: "center",
        },
        {
          title: this.$t("consume.a14"),
          dataIndex: "siteName",
          align: "center",
        },
        {
          title: this.$t("consume.a15"),
          dataIndex: "clientName",
          align: "center",
        },
        {
          title: this.$t("consume.a16"),
          customRender: (text, record, index) => {
            if (record.status == "001") {
              return this.$t("consume.a7");
            } else if (record.status == "002") {
              return this.$t("consume.a8");
            } else {
              return "-";
            }
          },
          align: "center",
        },
      ],
      priceColumns: [
        {
          title: this.$t("consume.a9"),
          children: [
            {
              title: this.$t("consume.a17"),
              dataIndex: "mon",
              align: "center",
            },
            {
              title: this.$t("consume.a18"),
              dataIndex: "tue",
              align: "center",
            },
            {
              title: this.$t("consume.a19"),
              dataIndex: "wed",
              align: "center",
            },
            {
              title: this.$t("consume.a20"),
              dataIndex: "thurs",
              align: "center",
            },
            {
              title: this.$t("consume.a21"),
              dataIndex: "friday",
              align: "center",
            },
            {
              title: this.$t("consume.a22"),
              dataIndex: "saturday",
              align: "center",
            },
            {
              title: this.$t("consume.a23"),
              dataIndex: "sunday",
              align: "center",
            },
          ],
        },
        {
          title: this.$t("consume.a10"),
          dataIndex: "lunch",
          align: "center",
        },
        {
          title: this.$t("consume.a11"),
          dataIndex: "supper",
          align: "center",
        },
        {
          title: this.$t("consume.a12"),
          dataIndex: "snack",
          align: "center",
        },
      ],
    };
  },
  mounted() {
    this.loading = true;
    const data = {
      siteId: this.$route.query.id,
    };
    // console.log("data", data);
    getEquipmentList(data)
      .then((res) => {
        // console.log(res);
        this.loading = false;
        const { data } = res;
        this.cardlist = data;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    // 图标获取
    getImg(value) {
      // console.log(value);
      if (value !== undefined) {
        return require(`../../../public/images/consume/${value}.png`);
      }
    },
    // 详情弹窗关闭按钮
    Cancel() {
      this.visible = false;
    },
    // 获取设备详情信息
    details(value) {
      this.visible = true;
      this.detailsLoading = true;
      const data = {
        siteId: this.$route.query.id,
        consumeReaderId: value,
      };
      getDetailsInfo(data)
        .then((res) => {
          // console.log(res);
          this.detailsLoading = false;
          const { consumeInfoBase } = res.data;
          const { consumeDeviceSetInfoBean } = res.data;
          const { consumeRulesBean } = res.data;
          this.infoData = [consumeInfoBase];
          if (consumeDeviceSetInfoBean == null) {
            this.timeData = [
              { breakfast: "-", lunch: "-", supper: "-", snack: "-" },
            ];
          } else {
            let time = consumeDeviceSetInfoBean.dinnerInformations;
            let timeData = [
              { breakfast: "", lunch: "", supper: "", snack: "" },
            ];
            for (let i = 0; i < time.length; i++) {
              let mealTimes = time[i].mealTimes;
              switch (mealTimes) {
                case 1:
                  timeData[0].breakfast = `${time[i].startTime}~${time[i].endTime}`;
                  break;
                case 2:
                  timeData[0].lunch = `${time[i].startTime}~${time[i].endTime}`;
                  break;
                case 3:
                  timeData[0].supper = `${time[i].startTime}~${time[i].endTime}`;
                  break;
                case 4:
                  timeData[0].snack = `${time[i].startTime}~${time[i].endTime}`;
                  break;
              }
            }
            this.timeData = timeData;
          }
          if (consumeRulesBean.consumeDinnerRuleList.length == 0) {
            this.priceData = [
              {
                mon: "-",
                tue: "-",
                wed: "-",
                thurs: "-",
                friday: "-",
                saturday: "-",
                sunday: "-",
                lunch: "-",
                supper: "-",
                snack: "-",
              },
            ];
          } else {
            let price = consumeRulesBean.consumeDinnerRuleList;
            let priceData = [
              {
                mon: "",
                tue: "",
                wed: "",
                thurs: "",
                friday: "",
                saturday: "",
                sunday: "",
                lunch: "",
                supper: "",
                snack: "",
              },
            ];
            for (let i = 0; i < price.length; i++) {
              let dinnerType = price[i].dinnerType;
              switch (dinnerType) {
                case 1:
                  let consumeTimesSetList = price[i].consumeTimesSetList;
                  for (let i = 0; i < consumeTimesSetList.length; i++) {
                    let wedDat = consumeTimesSetList[i].wedDat;
                    switch (wedDat) {
                      case 1:
                        priceData[0].mon = consumeTimesSetList[i].priceMoney;
                        break;
                      case 2:
                        priceData[0].tue = consumeTimesSetList[i].priceMoney;
                        break;
                      case 3:
                        priceData[0].wed = consumeTimesSetList[i].priceMoney;
                        break;
                      case 4:
                        priceData[0].thurs = consumeTimesSetList[i].priceMoney;
                        break;
                      case 5:
                        priceData[0].friday = consumeTimesSetList[i].priceMoney;
                        break;
                      case 6:
                        priceData[0].saturday =
                          consumeTimesSetList[i].priceMoney;
                        break;
                      case 7:
                        priceData[0].sunday = consumeTimesSetList[i].priceMoney;
                        break;
                    }
                  }
                  break;
                case 2:
                  priceData[0].lunch =
                    price[i].consumeTimesSetList[0].priceMoney;
                  break;
                case 3:
                  priceData[0].supper =
                    price[i].consumeTimesSetList[0].priceMoney;
                  break;
                case 4:
                  priceData[0].snack =
                    price[i].consumeTimesSetList[0].priceMoney;
                  break;
              }
            }
            this.priceData = priceData;
          }
        })
        .catch((err) => {
          console.log(err);
          this.detailsLoading = false;
        });
    },
  },
  components: {
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
#equipment {
  height: 100%;
}
.express {
  display: flex;
}
.expressli {
  display: flex;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
}
.expressli img {
  margin-right: 5px;
}
.main {
  width: 100%;
  height: calc(100% - 30px);
}
.card_container {
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.card {
  width: 180px;
  height: 90px;
  margin: 0 auto 15px auto;
  background-color: #f2f4f7;
  border: 1px solid #f2f4f7;
  border-radius: 3px;
  cursor: pointer;
  transition: 200ms;
}
.card:hover {
  border: 1px solid #7682ce;
}
.title {
  padding: 10px;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.empty {
  width: 180px;
  height: 1px;
  margin: 0 auto;
}
.footer {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.info {
  margin-bottom: 30px;
  font-size: 14px;
}
.time {
  margin-bottom: 30px;
}
</style>